#svg-container{
  position: relative;

  .balloon{
    &-container{
      position: absolute;
      top: 10rem;
      left: 10rem;
      padding: 1rem;

      border-radius: .2rem;
      background: #FEFEFE;
    }

    &-wrapper{
      p{
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
}
