@import "./variables";
body{
  overflow-x: hidden;
  font-size: $font-size-sm;
  line-height: $linge-height-sm;
}

.content{
  margin: 0 1.5rem 0;
  padding-top: 2rem;
}

.notify{
  top: 3.5rem;
}
.menu{
  &__item{
    padding: 0 0.5rem;
    height: auto;
    min-height: 1.8rem;

    &:first-child{
      padding-top: 0.25rem;
      height: 40px;
      color: $menu-black-color;

      .menu__item-text{
        font-weight: normal;
      }
    }



    &_collapse {
      &:after {
        top: 8px;
        right: 8px;
      }
    }

    &-group{
      max-height: 40px;

      &_active{
        max-height: 400px;
        .menu__item-icon{
          background: $menu-active-first-background;
        }
      }
    }
  }
  &__item-text{
    font-weight: normal;
  }
  &__item-icon{
    font-size: 1.55rem;
    height: 40px;
    line-height: 40px;
    color: $clr-black;

    &:after {
      z-index: 60;
      position: relative;
      font-family: "Icons" !important;
    }

    &.icon-fade:after {
      opacity: 0.7;
    }
  }

}

.sidebar__menu{
  overflow-y: auto;
  overflow-x: hidden;

  &.sidebar_super_scroll{
    overflow-y: hidden;
    position: relative;

    .super_scroll_app{
      max-height: calc(100vh - 48px);
    }
  }
}




.sidebar-mini {
  .sidebar{
    width: $sidebar-width-min;
    overflow: hidden;
  }

  .menu__item-group {
    max-height: 40px;
  }
  .sidebar:hover .menu__item-group_active {
    height: auto;
    max-height: 400px; /* Высчитать в Angular для анимации*/
  }
}


.v100_scrollbar{
  max-height: calc(100vh - 50px);
}


.small_dropzone{
  .dropzone{
    min-height: 2rem !important;
  }
  .table-responsive {
    min-height: auto !important;
    margin-bottom: 0rem!important;
  }
}


.activeBox{
  margin: -0.3rem -0.7rem;
  padding: 0.3rem 1rem;
  background: $clr-yellow-opacity-2x;
  &.margin{
    margin: 0.2rem -1rem;
  }
}


.go_back_url{
  position: relative;
  font-size: 2rem;
  cursor: pointer;
  margin-top: 0rem;
  &:before{
    color: $c-red;
  }
}

.go_back_header{
  display: flex;
  align-items: center;
  h1, h2, h3{
    margin-top: 0rem;
    margin-bottom: 0rem;
    font-size: 1.8rem;
  }
  margin-bottom: 0.5rem;
}


.table th, .table td {
  padding: 0.3rem 0.75rem!important;
}


.table-color{
  tr[data-color="all_work"]{
    background: #ffeb9b !important;
  }
  tr[data-color="done"]{
    background: #90e896 !important;
  }
  tr[data-color="fail"]{
    background: #efb7b5 !important;
  }
  tr[data-color="new"]{
    background: #ffeb9b !important;
  }
  tr[data-color="today"]{
    background: #90e896 !important;
  }
  tr[data-color="bad"]{
    background: #efb7b5 !important;
  }
}


.chart-holder{
  width: 100%;
  max-height: 30rem;
  margin: 1rem 0 4rem 0;
}

.select2-container--default .select2-results>.select2-results__options {
  max-height: 250px;
}
.small-padding-fix .select2-container .select2-selection--multiple {
  min-height: 28px;
  padding: 0 0.4rem !important;
  .select2-selection__rendered {
    line-height: 1.1rem !important;
  }
  .select2-selection__choice {
    margin-top: 3px;
  }
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: $c-yellow !important;
  color: $c-dark !important;
}
