
*{
	margin: 0;
	padding: 0;
	border:0;
}

.fht-table,
.fht-table thead,
.fht-table tfoot,
.fht-table tbody,
.fht-table tr,
.fht-table th,
.fht-table td {
	margin: 0;
}

.fht-table{
	border: 0 none;
	height: auto;
	width: auto;
	border-collapse: collapse;
	border-spacing: 0;
	/*table-layout: fixed;  Algoritmo de distribucion fijo */
    /*white-space:nowrap;   Impide los saltos de línea automáticos */
}

.fht-table th,.fht-table td {
    overflow: hidden;
}

.fht-table-wrapper,
.fht-table-wrapper .fht-thead,
.fht-table-wrapper .fht-tfoot,
.fht-table-wrapper .fht-fixed-column .fht-tbody,
.fht-table-wrapper .fht-fixed-body .fht-tbody,
.fht-table-wrapper .fht-tbody {
	overflow: hidden;
	position: relative;
}

.fht-table-wrapper .fht-fixed-body .fht-tbody,
.fht-table-wrapper .fht-tbody {
	overflow: auto;
}

.fht-table-wrapper .fht-table .fht-cell {
	overflow: hidden;
	height: 1px;
}

.fht-table-wrapper .fht-fixed-column,
.fht-table-wrapper .fht-fixed-body {
	top: 0;
	left: 0;
	position: absolute;
}

.fht-table-wrapper .fht-fixed-column {
	z-index: 1;
}

.fht-fixed-body .fht-thead table {
	margin-right: 20px;
	border: 0 none;
}

/*For Examples*/

.ContenedorTabla {
	height: 400px;
	margin: 0 auto;
	overflow: auto;
	width: 1024px;
	position: relative;
}

/*When measures are used in%*/
/*.ContenedorTabla {
	height: 80%;
	margin: 0 auto;
	overflow: auto;
	width: 100%;
	position: fixed;
}*/ 

.header, .main {
    display: inline-block;
    height: auto;
    width: 100%;
}
.titulosHeader {
    border-bottom: 1px solid #e8bb25;
    height: auto;
    margin-bottom: 10px;
    padding-bottom: 8px;
    padding-top: 8px;
    width: 100%;
} 


.celda_encabezado_general {
    background-color: #003399;
    border: 1px solid #ccc;
    color: #ffffff;
    font-weight: bold;
    padding: 2px 4px;
    text-align: center;
}
._Separador{
	background-color: #fff;
	height: 12px;
	border-left: 1px solid #ccc;
	border-right: 1px solid #ccc;
	width: 7px;
}
._Separador div{
	width: 4px;
}
.celda_normal {
    /*background-color: #fff;*/
    border: 1px solid #ccc;
    padding: 2px 4px;
}

/*style excel*/
.excel_cell{
	border: 1px solid #CCC;
	color: #222;
	text-align: center;
	font-size: 13px;
	font-weight:  normal;
	padding: 4px;
	white-space: pre-line;
	empty-cells: show;
}
._cell_header{
	background-color: #EEE;
}
._cell_Default{
	background-color: #FFF;
	text-align: left;
}

.excel_cell div{
	width: 30px;
	height: 20px;
}
