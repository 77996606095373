@import "./variables";
@import "./mixins";
@import "./modifiers";

html{
  font-size: ($rem) + px;
  height: 100%;
  background: $c-white2;
}

body{
  height: 100%;
  //min-height: 100%;
  color: $text-gray-color;
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $linge-height-base;
  background-color: transparent;
  font-variant-numeric: proportional-nums;
  -moz-font-feature-settings: "pnum";
  -webkit-font-feature-settings: "pnum";
  font-feature-settings: "pnum";

  a {
    color: $link-new-color;

    &:hover {
      color: $link-new-hovered-color;
    }
  }


}

/* Global modifier */
.text-normal{ @extend %text; }
.text-italic{ @extend %text-italic; }
.text-light{ @extend %text-light; color: inherit !important;}
.text-bold{ @extend %text-bold; }
.text-bold-italic{ @extend %text-bold-italic; }
.text-sm{ @extend %text-sm; }
.text-big{ @extend %text-big; }
.text-sm-bold{ @extend %text-sm-bold; }
.bg-dark{ background: $text-gray-color; color: $clr-white; }
.bg-none{ background: transparent !important; }
.bg-info{ background: $c-akcent1 !important; }
.bg-success{ background: $c-green !important; }
.bg-warning{ background: $c-yellow !important; }
.bg-danger{ background: $c-normal-a !important; }
.bg-default{ background: $c-grey !important; }
.bg-red{ background: rgb(222, 29, 37)!important; }
.bg-grey-a{ background: $c-grey-a !important; }
.bg-primary{ background: $c-akcent4 !important; }
.bg-yellow2{ background: $c-yellow2 !important; }
.bg-akcent7{ background: $c-akcent7 !important; }
.bg-green-light{ background: $c-green-light !important; }
.c-black{color: $clr-black-total;}
.c-white{color: $clr-white;}
.c-white-i{color: $clr-white !important;}
.c-dark{color: $clr-black;}
.c-red{color: $clr-red-dark;}
.c-red2{color: $clr-red-dark;}
.c-yellow{color: $c-yellow;}
.c-yellow2{color: $c-yellow2;}
.c-orange{color: $c-orange;}
.c-green{color: $c-green;}
.c-grey{color: $c-grey;}
.c-grey2{color: $c-grey2;}
.c-light-grey{color: rgba(0,0,0,0.55);}
.c-pink{color: $clr-pink;}
.c-dark-grey{color: $c-dark-grey;}
.c-blue{color: $c-blue;}
.c-normal{color: $clr-red;}
.c-checkbox{color: $c-checkbox;}
.c-normal-h{color: $c-normal-h;}
.c-normal-a{color: $c-normal-a;}
.c-select{color: $c-select;}
.c-select-a{color: $c-select-a;}
.c-disable{color: $c-disable;}
.c-akcent1{color: $c-akcent1;}
.c-akcent2{color: $c-akcent2;}
.c-akcent3{color: $c-akcent3;}
.c-akcent4{color: $c-akcent4;}
.c-akcent5{color: $c-akcent5;}
.c-akcent6{color: $c-akcent6;}

.p0{padding: 0 !important;}
.m0{ margin: 0 !important;}
.mt-2r{ margin-top: 2rem !important;}
.mb-2r{ margin-bottom: 2rem !important;}
.mr-2r{ margin-right: 2rem !important;}
.ml-2r{ margin-left: 2rem !important;}
.pt-08r{ padding-top: 0.8rem !important;}
.pt-2r{ padding-top: 2rem !important;}
.pb-2r{ padding-bottom: 2rem !important;}
.pr-2r{ padding-right: 2rem !important;}
.pl-2r{ padding-left: 2rem !important;}
.pt-4r{ padding-top: 4rem !important;}
.pb-4r{ padding-bottom: 4rem !important;}
.pr-4r{ padding-right: 4rem !important;}
.pl-4r{ padding-left: 4rem !important;}
.pt-5r{ padding-top: 5rem !important;}
.pb-5r{ padding-bottom: 5rem !important;}
.pr-5r{ padding-right: 5rem !important;}
.pl-5r{ padding-left: 5rem !important;}

h1, .caption-bg{ @extend %h1; }
h2, .caption, .caption-md{ @extend %h2; }
h3, .caption-sm{ @extend %h3; }

ul.dashed { list-style-type: none; }
ul.dashed > li { text-indent: -10px; }
ul.dashed > li:before { content: "-"; text-indent: -10px; padding-right: 5px;}

.bold{ font-weight: bold;}
.nowrap{white-space: nowrap;}
.cart{ background: $c-white2;}
.text-throught{ text-decoration: line-through; }
.no-decortion{ text-decoration: none;}
.no-decortion:hover{ text-decoration: none;}
.no-wrap{white-space: nowrap;}
.fa-2x{font-size: 1.4rem;}
div:focus { outline: none; }
.c-pointer { cursor: pointer; }
.text-eclips { text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
.o-hidden { overflow: hidden; }
.m-h-500 { max-height: 500px; }
.lh-button { line-height: 2.375rem; }
.h-1px{ height: 1px;}
.img-circle {border-radius: 50%;}

.link {
  color: $link-new-color;
  text-decoration: none;

  &:hover{
    color: $link-new-hovered-color;
    text-decoration: underline;
  }
}
li {
  &:active, &:focus {
    outline: none;
  }
}



/* Custom CSS not BEM */
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: $close-color;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  cursor: pointer;

  &_sm{
    font-size: 1.1rem;
  }
}
.table-responsive {
    display: block;
    width: 100%;
    min-height: 15rem;
    overflow-x: auto;
    position: relative;
}
.table-bordered{
  tr:first-child{
    border-top: 2px solid #E4E4E4;
  }
  tr:last-child{
    border-bottom: 2px solid #E4E4E4;
  }
}
.btn{ padding: 0.5rem 0.5rem; }
.dz-dash{ text-decoration: underline; }
.ui-helper-hidden-accessible{ display: none!important; }
.images-preview {
  height: 7.5rem;
  width: 11.19rem;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  border: 1px solid #EB767B;
  margin-right: 0.5625rem;
  margin-bottom: 0.5625rem;

  &_small{
    height: 3rem;
    width: 4rem;
  }
}
.popover.left > .arrow{
  right: -15px !important;
}

.datepicker table tr td.active:active, .datepicker table tr td.active.highlighted:active, .datepicker table tr td.active.active, .datepicker table tr td.active.highlighted.active {
  color: $clr-white;
  background-color: $clr-pink;
  border-color: $clr-pink;
}

// Modal
.modal-open{
  .modal {
    z-index: $z-modal;
  }
  .modal-backdrop{
    z-index: $z-modal - 1;
  }
  .modal-footer{
    display: block;
  }
}
.modal-backdrop{
  background-color: $clr-black-soft!important;
}

// Sweet Alert
.swal2-modal{
  border: 1px solid $c-grey2;
  border-radius: 4px !important;
  background-color: $clr-white;
  box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.24);

  .swal2-title{
    line-height: 32px;
  }

  .swal2-buttonswrapper{
    margin-top: 2rem;
  }

  .swal2-select{
    border: 1px solid #D8D7D7;
    width: 100%;
    height: 2.375rem;
    padding: 0.2rem 0.875rem 0.16rem 0.875rem;
    outline: 0;
    background: transparent;
  }

  .btn{
    @extend .button;
    outline: none;
    margin: 0 1rem;

    &:focus{
      box-shadow: none;
    }

    &.swal2-confirm{
      @extend .button_main;
    }

    &.swal2-cancel{
      @extend .button_sub;
    }

    &:not(:disabled):not(.disabled):active {
      box-shadow: 0 0 0 0.2rem var(--button-red-background);
      border-color: var(--button-red-background);
    }
  }
}

// Gutters
.row.no-gutters{
  &.gutters-none{
    margin: 0 0rem;
    > [class*="col"]{
      padding: 0 0rem;
    }
  }
  &.gutters-0{
    margin: 0 -0.15rem;
    > [class*="col"]{
      padding: 0 0.15rem;
    }
  }
  &.gutters-1{
    margin: 0 -0.25rem;
    > [class*="col"]{
      padding: 0 0.25rem;
    }
  }
  &.gutters-2{
    margin: 0 -0.5rem;
    > [class*="col"]{
      padding: 0 0.5rem;
    }
  }
  &.gutters-3{
    margin: 0 -0.75rem;
    > [class*="col"]{
      padding: 0 0.75rem;
    }
  }
  &.gutters-4{
    margin: 0 -1rem;
    > [class*="col"]{
      padding: 0 1rem;
    }
  }
  &.gutters-5{
    margin: 0 -2rem;
    > [class*="col"]{
      padding: 0 2rem;
    }
  }
}

.flat-content-wrapper .ps-content{
  padding-right: 10px;
}
.bg_crop{
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 100%;
  height: 100%;
}


/* Blocks */
.sidebar{
  height: 100%;
  //min-height: 100vh;
  background: $sidebar-gray-background;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  width: $sidebar-width;
  overflow-y: auto;
  overflow-x: hidden;
  transition: width 400ms ease 0s;

  & > *{
    z-index: $z-menu;
  }
  &__bg{
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    background: $sidebar-red-background;
    width: $sidebar-width-min;
    z-index: $z-menu - 1;
    @media(max-width: 768px) {
      background: transparent;
    }
    &_crm{
      background: $c-white2;
      border-right: 1px solid $sidebar-gray-background;
    }
  }
  &__menu{
    flex-grow: 1;
  }
  &__client{
    flex-basis: 32px;
  }
  &__settings{
    flex-basis: 32px;
  }
  &__exit{
    flex-basis: 32px;
  }
  &__user{
    flex-basis: 48px;
  }
}

.menu{
  &_crm{
    .menu__item:hover .menu__item-text {
      //width: 100%;
      //border-bottom: 4px solid $clr-red;
      //padding-bottom: 4px;
    }
  }

  &__item-list{
    display: inline-block;
    width: $sidebar-width - $sidebar-width-min;
  }
  &__item-icon{
    display: inline-block;
    float: left;
    color: $icon-white-color;
    width:$sidebar-width-min;
    height:$sidebar-width-min;
    font-size: 1.75rem;
    line-height:$sidebar-width-min;
    cursor: pointer;
    font-family: $font-family-icon !important;
    speak: none;
    font-style: normal;
    font-weight: 500;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    @media(max-width: 768px) {
      color: #000;
    }
    &:focus{
      outline: none;
    }

    &_new-main:after {
      content: "\e976";
      font-size: 1.45rem;
    }
    &_small-lesson:after {content: "\e94b";}
    &_ad-module2:after {content: "\e961";}
    &_users:after {content: "\e90e";}
    &_small-crowd:after {content: "\e940";}
    &_time-refresh:after {content: "\e922";}
    &_new-mortgage:after {content: "\e978";}
    &_new-hot:after {content: "\e975";}
    &_new-hot-white:after {content: "\e97d";}
    &_dashboard:after {content: "\e903";}
    &_calendar:after {content: "\e985";}
    &_key-exchange:after {content: "\e904";}
    &_online-support:after {content: "\e907";}
    &_permanent-job:after {content: "\e987";}
    &_crowd:after {content: "\e940";}
    &_users:after {
      font-family: $font-family-icon !important;
      font-style: normal;
      font-weight: 500;
      font-variant: normal;
      content: "\e90e";
    }
    &_import:after {content: "\e964";}
    &_lampochka:after {content: "\e96d";}
    &_mortgage:after {content: "\e952";}
    &_building:after {content: "\e96f";}
    &_housing-edit:after {content: "\e988"; font-size: 3rem!important;}
    &_leads:after {content: "\e989"; font-size: 3rem!important;}
    &_rocket {
      font-family: 'FontAwesome' !important;
    }
    &_logo-youtube{

      &:before{
        content: "\e96b";
        color: #fff;
      }
      &:after{
        content: "\e96c";
        color: $clr-red-original;
        margin-left: -1.4365234375em;
      }
    }
  }

  &__item-group{
    width: $sidebar-width;
    position: relative;
    overflow: hidden;
    transition: $transition-menu;
    max-height: 48px;

    &_active{
      max-height: 214px;

      .menu__item:first-child{
        background: $menu-active-first-background;
      }
      .menu__item-icon{
        background: $menu-icon-background;
        @media(max-width: 768px) {
          background: $menu-active-first-background !important;
        }
      }
    }

    &_view{
      max-height: 214px;

      .menu__item_collapse, &.menu__item-group_active .menu__item.menu__item_collapse{
        &:after{
          content: none;
        }
      }
    }
  }

  &__item{
    display: block;
    position: relative;
    height: 2rem;
    padding: 0 1rem;
    color: $menu-black-color !important;
    font-weight: 300;
    line-height: 1;

    &_big{
      @extend .menu__item;
      height: 3rem;
      padding-top: .2rem;

      >span{
        line-height: 1.2rem;
      }
    }

    &_active{
      background: $menu-active-background;
    }
    &_active:first-child{
      background: $menu-active-first-background;
    }

    &:hover{
      color: $menu-black-hover-color;
      text-decoration: none;
      background-color: $menu-hover-background;
    }

    &:hover &-text{
      //border-bottom: 1px solid $clr-red;
    }
    &:hover:first-child &-text{
      //border-bottom: 2px solid $clr-red;
    }
    &:last-child{
      //margin-bottom: 1rem;
    }
    &:first-child{
      height: 48px;
      font-family: $font-family-base;
      font-weight: normal;
      line-height: 1.625rem;
      padding-top: 0.45rem;
      margin-bottom: 0;

      .menu__item-text{
        padding-top: 0.3rem;
        padding-bottom: 0;
      }
    }
    &_collapse{
      &:after{
        position: absolute;
        top: 12px;
        right: 16px;
        font-size: 1.125rem;
        color: $icon-white-color;
        font-family: $font-family-icon !important;
        content: "\e97e";
      }
    }
    &-group_active &.menu__item_collapse{
      &:after{
        content: "\e977";
      }
    }

  }
  &__item-text {
    &_fix{
      padding-top: 0.3rem;
    }
    &_nowrap{
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 1rem;
    }
  }

  &__item-text,
  &__item-text_nowrap{
    position: relative;
    width: auto;
    display: block;
    border-bottom: 3px solid transparent;
    padding-bottom: 0.25rem;
    padding-top: 0.5rem;
  }

  %item-text-icon{
    font-weight: 500;
    color: $icon-red-color;
  }

  &__item-text-icon{
    @extend %item-text-icon;
    font-size: 1.1rem;
    position: absolute;
    right: 0;

    &_sm{
      @extend %item-text-icon;
      font-size: 0.8rem;
      &:before{
        color: inherit;
      }
    }

    &_line{
      position: relative;
      margin-left: 0.25rem;
    }

    &_previews{
      @extend %item-text-icon;
      font-size: 1.2rem;
      top: 0.3rem;
    }

    &_hot{
      font-size: 1.15rem;
      color: $icon-yellow-color !important;
      line-height: 1.625rem;
    }
  }

   &__item-flat-group{
    padding-left: $sidebar-width-min;
    width: $sidebar-width;
  }
  &__item-flat{
    display: inline-block;
    height: 2rem;
    //line-height: 2.1875rem;
    width: auto;
    margin: 0 1rem;
    color: $menu-black-color;

    &_active{
      font-family: $font-family-base; font-weight: bold;
    }
    &_pressed{
      @media (max-width: 768px) {
        margin: 0 1rem;
      }
    }
    &_block{
      @media (max-width: 768px) {
        display: none;
      }
    }
    &:hover{
      color: $text-gray-color;
      text-decoration: none;
    }
    &:hover .menu__item-text{
      text-decoration: none;
      border-bottom: 1px solid $clr-red-dark;
    }
  }
}

.user{
  width: $sidebar-width;
  &__avatar{
    display: block;
    float: left;
    width:$sidebar-width-min;
    height:$sidebar-width-min;
    cursor: pointer;
    padding: 0.5625rem;

    &_icon {
      width: 2.25rem;
      height: 2.25rem;
    }

    &-img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  &__name{
    display: inline-block;
    height: $sidebar-width-min;
    line-height: 2.1875rem;
    width: $sidebar-width - $sidebar-width-min;
    padding: 0 1rem;
    color: $menu-black-color;
    font-family: $font-family-base;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;

    &:hover{
      color: $menu-black-hover-color;
      text-decoration: none;
    }
  }
}

.logo{
  height: 1.125rem;

  &_org{
    width: auto;
  }

  &_and{
    padding: 0 0.5rem;
    color: $c-dark-grey;
  }

  &__img{
    height: inherit;
    width: inherit;
  }
}

.content{
  margin:  1rem;
}

.panel{
  @extend %panel;
  border: 1px solid $c-grey2;
  border-radius: 3px;
  background: $c-white2;
  padding: 1.7rem 1.6rem 1.7rem 1.6rem;

  &_grey{
    background: $c-grey;
    border: 1px solid $c-grey;
  }

  &_clear{
    padding: 0;
    background: transparent;
    border: 0;
  }

  &_transparent{
    background: transparent;
  }

  &_border-off,
  &_noborder{
    @extend %panel;
    border: 0;
    padding: 0 0 2.3125rem 0;
  }

  &__header{
    margin-bottom: 2rem;
    @extend h2;
  }
  &__content{

  }
  &__footer{
    margin-top: 2.375rem;
  }
}

.button{
  display: inline-block;
  text-transform: uppercase;
  @extend %text-sm;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
  position: relative;
  top: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: $font-size-button;
  font-weight: normal;
  letter-spacing: 0.4px;

  color: $btn-color;
  background-color: #fff;
  border: solid $button-red-border 2px;
  border-radius: $btn-border-radius;
  padding: $btn-padding;
  height: $btn-height;
  line-height: $btn-line-height;
  transition: all 10ms ease-out;
  position: relative;
  z-index: 1;

  &:hover, a.button:hover {
    //box-shadow: 1px 1px 1px 0 $btn-color-shadow-hover;
    box-shadow: none;
    border-color: $link-new-hovered-color;
    text-decoration: none !important;
    color: $btn-color!important;
  }
  &:active{
    //box-shadow: 1px 1px 1px 0 $btn-color-shadow-hover;
    outline: none !important;
    background: #fadfe0;
  }

  &_block{
    width: 100%;
  }

  &_active{
    background: $c-yellow !important;
  }

  &_back{
    position: relative;
    padding-left: 1.5rem;
    display: inline-block;
    border-radius: 0;
    border: 0;
    background: transparent !important;
  }

  &_sm.button, &_crm.button{
    @extend %button_sm;
  }

  &__text{
    @extend .button;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
    padding-left: 0;
    position: relative;
    z-index: 1;
    transition: none;
    line-height: $btn-line-height;
  }
  &__svg{
    position: absolute;
    z-index: 0;
    top: 0;
    left: 1px;
    height: $btn-height;
    width: 1.5rem;

    path {
      stroke: $c-pink;
      stroke-width: 3px;
      fill: $btn-color-background;
    }
  }
  &:hover &__svg{
    path {
      fill: $btn-color-background-hover;
    }
  }
  &:active &__svg{
    path {
      fill: $c-pink;
    }
  }
  &:hover &__text{
    background: $btn-color-background-hover;
  }
  &:active &__text{
    background: $clr-pink;
  }

  &_circle{
    border-radius: 50%;
    min-width:0 !important;
    padding: 0;
    width:2.5rem;
    height:2.5rem;
    line-height: 2.5rem;
    //font-weight: bold;
    //font-size: 1.8rem;
    //line-height: 1.8rem;
    //border: 1px solid $c-grey2;

    &.button:hover{
      //box-shadow: 1px 1px 1px 0 $btn-color-shadow-hover;
      background: $btn-color-background-hover;
      //color: $clr-red;
    }
    &:active{
      //box-shadow: 1px 1px 1px 0 $btn-color-shadow-hover;
      background: $btn-color-background-hover;
    }
    &:disabled{
      border: 1px solid $c-grey2 !important;
      background:#727272 !important;
      cursor: not-allowed;

      &:hover{
        //box-shadow: 1px 1px 1px 0 $btn-color-shadow-hover;
        //color: $c-dark-grey;
      }
    }
  }

  &_title{
    border: 0px!important;
  }
  &_icon{
    width: 2.5rem;
    height: 1.375rem;
    line-height: 1.55rem;
    //border: 1px solid $c-grey2;
    border-radius: 1.25rem;
    border: 0px!important;
    padding: 0;
    color: $clr-red-dark;
    background: $c-grey-a5;
    text-overflow: clip;

    & > i{
      color: $clr-red-dark;
      &.fa{
        position: relative;
        top: -0.11rem;
      }
    }

    &:hover{
      background: $c-grey;
      //color: $c-red !important;
    }
    &_sub{
      & > i{
        color: $c-dark-grey !important;
      }
    }
    &.button_disabled{
      border: 0;
    }
  }
  &_main{
    background-color: $button-red-background!important;
    color: $btn-main-color!important;
    &:hover{
      color: $btn-main-color!important;
    }
  }

  &_sub, &_crm{
    background-color: $clr-pink;
    border-color: $clr-pink;
    color: $btn-sub-color;
    padding: $btn-sub-padding;
    &:active{
      color: #fff!important;
      background-color: $clr-pink;
    }
    &:hover {
      color: #fff!important;
      background-color: $clr-pink;
    }
  }

  &_grey{
    background-color: $btn-grey-color-background;
    border-color: $btn-grey-color-border;
    color: $btn-grey-color;
    padding: $btn-grey-padding;

    &:active{
      color: $btn-grey-color-hover;
    }
    &:hover {
      color: $btn-grey-color-hover;
    }
  }

  &_white{
    border: 1px solid $c-grey2;
    background-color: $clr-white;
    color: $text-gray-color;
    padding: $btn-grey-padding;

    &:active{
      color: $btn-grey-color-hover;
    }
    &:hover {
      color: $btn-grey-color-hover;
    }
  }

  &_search{
    padding: 0 2rem;
    font-size: 0.85rem;
    background-color: $button-red-background!important;
    color: #fff!important;
    &:hover{
      border-color: $clr-red-darker!important;
      background-color: $clr-red-darker!important;
      color: #fff!important;
    }
  }

  &_nowrap{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &_accept{
    overflow: hidden;

    & > .button__text{
      transition: all 200ms linear;
      -webkit-transform: translate3d(0,0,0);
      -moz-transform: translate3d(0,0,0);
      transform: translate3d(0,0,0);

      &:after{
        content: "✔";
        position: absolute;
        display: block;
        width: 100%;
        top: 100%;
        left: 0;
        font-weight: 700;
      }
    }
    &_on{
      & > .button__text{
        -webkit-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        transform: translateY(-100%);
      }
    }
  }

  &__text{
    position: relative;
    display: block;
  }

  &_disabled, &:disabled{
    background-color: #b8b8b8!important;
    cursor: not-allowed!important;
    color: #7a7a7a!important;
    border: solid #a1a1a1 2px!important;
    &:hover {
      background-color: #b8b8b8!important;
      cursor: not-allowed!important;
      color: #7a7a7a!important;
      border: solid #a1a1a1 2px!important;
    }
  }
  &:active{
    outline: none;
  }
  &:focus {
    outline:none;
  }
  &:hover {
    outline:none;
    color: $btn-color-hover;
  }

  &.button-small{
    font-size: 0.65rem;
    padding: 0.2rem 0.5rem;
    height: auto;
    line-height: 1rem;
  }

}

button.button{
  min-width: 4.25rem;
  border-radius: $btn-border-radius !important;
  text-transform: uppercase !important;

  &_sm, &_crm{
    @extend %button_sm;
  }
}

a.button{
  text-decoration: none;

  &:hover{
    text-decoration: none;
  }
}

.button-group{
  display: flex;
  padding: 0 1rem;
  border: 1px solid $c-grey2;
  border-radius: $btn-border-radius;
  background: $clr-white;

  & > .button{
    display: block;
    flex-grow: 1;
    text-transform: none;
    float: left !important;
    border-radius: 0 !important;
    padding: 0 0.5rem;
    margin: 0 0.3rem;
    background: $clr-white;
    border: 0;
    color: $text-gray-color;
    font-size: $font-size-base;

    &:hover{
      box-shadow: none !important;
      background: $c-white !important;
      border-bottom: 4px solid $c-yellow2;
    }

    &.button_active{
      box-shadow: none !important;
      background: $clr-white !important;
      border-bottom: 4px solid $c-red!important;
    }

  }
}


.button-group-main{
  display: flex;
  min-width: fit-content;
  margin-bottom: 0.6rem;
  & > .button{
    display: block;
    flex-grow: 1;
    text-transform: none;
    float: left !important;
    border-radius: 10px!important;
    border: 1px solid $clr-red;
    padding: 0 0.5rem;
    margin: 0 0.1rem 0;
    background: $clr-white;
    color: $text-gray-color;
    font-size: $font-size-base;

    &:hover{
      background: $clr-red!important;
      color: $clr-white!important;
      @include media-md{
        background: $clr-white!important;
        color: $text-gray-color!important;
      }
    }

    &.button_active{
      background: $clr-red!important;
      color: $clr-white!important;
    }

  }
}



.button-picture{
  position: relative;

  .a:hover{
    text-decoration: none;
  }

  &_active{
    p{
      color: $c-green !important;
    }
    a{
      text-decoration: none;
    }
    .button-picture__img{
      border-color: $c-green;
    }
  }

  &__img{
    border: 2px solid $c-grey2;
    border-radius: 50%;
    width: 6rem;
    height: 6rem;
  }
  &__icon{
    position: absolute;
    //background: $clr-red;
    //color: $clr-white;
    width: 1.563rem;
    height: 1.563rem;
    right:0;
    bottom:0;
    z-index: 1;
    background: url("../../assets/images/download.svg");
  }
  &__url{
    position: absolute;
    left:0;
    top:0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
}

.btn-group{
  input{
    display: none!important;
  }
}


.form{

  &__group{
    margin-bottom: 0.6rem;
    position: relative;

    &_width-sm{
      width: 42px;
    }

    &__dropdown{
      position: absolute;
      top: 100%;
      min-width: 100%;
      z-index: 10;
      background: #FFFFFF;
      border: 1px solid $c-input-border;
      display: none;
      margin-top: -1px;
      @media (max-width: 768px) {
        border-radius: 1rem!important;
        position: fixed;
        top: 3rem;
        left: 0.5rem;
        min-width: calc(100% - 1rem);
        width: calc(100% - 1rem);
        border: 2px solid #868686;
        max-height: 80%;
        overflow: auto;
        opacity: 0;
        transition: all 200ms ease-out;
        display: block;
        visibility: hidden;
        &_opened{
          opacity: 1;
          top: 1rem;
          visibility: visible;
          z-index: 1020;
        }
        li {
          padding: 0.8rem 1rem!important;
          &.caption{
            color: $clr-black-total;
            font-family: $font-family-base;
            font-size: $font-size-base;
            line-height: $linge-height-base;
            font-weight: bold;
            display: block;
            text-align: left;
            background: #9F9F9F;
            margin: -12px 0 0px;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            padding: 0.7rem 1rem !important;
            display: flex;
            align-items: flex-end;
          }
          &+li{
            border-top: 1px solid #eee;
          }
        }
      }
      &_icon{
        margin-top: 1px;
        padding: 0 !important;
        box-shadow: -1px 2px 8px 0px rgba(0, 0, 0, 0.15);

        li{
          white-space: nowrap;
          padding: 5px 8px;
          @extend %text-sm;
        }
      }

      &_right{
        right: 3px;
      }

      &_opened{
        display: block;
      }

      &_ul{
        list-style: none;
        padding: 10px 0;
        border-radius: 3px;

        li{
          padding: 5px 15px;
          cursor: pointer;
          color: $text-main-color;

          &.selected{
            //background: $c-dark-grey;
          }

          &:hover, &:hover a, &:hover i:before {
            background: var(--clr-red);
            color: var(--clr-red-inside);
          }
        }
      }
    }

    &.primary-color input{
      padding: 0px;
      border: 0px;
    }

  }

  &__label{
    @extend %input-label;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 0;
    padding-left: 1rem;

    &.text-wrap {
      white-space: initial;
      text-overflow: initial;
      overflow: initial;
    }

    &:empty{
      margin-bottom: 0;
    }

    &_require{
      position: relative;
      &:before{
        display: inline-block;
        position: absolute;
        content: '';
        top: 0.275rem;
        left: 0.25rem;
        height: 0.375rem;
        width: 0.375rem;
        border-radius: 50%;
        //background-color: $li-dot-background;
        background-color: #f00;
      }
    }
  }
  &__input{
    border: 1px solid $c-grey2;
    background: #FFFFFF;
    position: relative;

    &_disabled{
      border: 1px solid $c-grey2;
      background: $c-grey;

      .form__input-close{
        display: none;
      }

    }
    &_error{
      border-color: $clr-red;
    }
    &_focus{
      background-color: $c-yellow !important;
    }

    &.input-group {
      .form__input-close{
        right: 45px;
      }
    }

    &-close{
      position: absolute;
      top: 0px;
      bottom: 0px;
      margin: auto;
      right: 4px;
      font-size: 1.3rem;
      font-weight: 100;
      line-height: 1;
      color: #000;
      text-shadow: 0 1px 0 #fff;
      opacity: .4;
      cursor: pointer;
      padding: 0;
      background: transparent;
      border: 0;
      -webkit-appearance: none;

      &:hover{
        opacity: .6;
      }
    }

    &__shortcut{
      @extend %text-sm;
      //position: absolute;
      //top: 100%;
      //left: 0;
      font-size: .8rem;
      width: 138px;
      border-radius: 3px;
      margin: 3px 0 -7px 2px;
      padding: 4px 1px 4px 4px;
      background: $c-white2 !important;
      opacity: 0.75;

      &:after{
        content: "";
        display: inline-block;
        width: 48px;
        height: .9rem;
        background: url('/assets/images/Shit-Plus-Enter.svg') no-repeat 0 0;
        background-size: contain;
        margin-left: .1rem;
        margin-top: .1rem;
      }
    }

    &.form__input_has-icon{
      padding-right: 3rem;

      &_sm{
        padding-right: 1.5rem;
      }
    }
    .form__input_icon{
      position: absolute;
      font-size: 1rem;
      top: 12px;
      right: 10px;
    }

    .ui-autocomplete {
      top: 100% !important;
      left: 0;
      border: 1px solid rgb(186, 186, 186) !important;
      box-sizing: content-box !important;
      padding: 5px 0 !important;
      width: 100% !important;

      z-index: 3;
      background: #fff;
      list-style: none;
      position: absolute;

      li {
        padding: 4px 5px;
        border: 0 !important;
        cursor: pointer;
      }
      .ui-menu-item-wrapper{
        background: transparent !important;
        border: 1px solid transparent !important;
        color: $text-gray-color;
        margin: 0 !important;
      }
      li:hover {
        background: #FDA967;
      }
    }
  }

  &__checkbox{
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    height: 2.125rem;

    &__disabled{
      cursor: not-allowed;
    }

    &__content{
      overflow: hidden;
      text-overflow: ellipsis;

      &_phone{
        min-width: 124px;
      }
    }
    .form__checkbox__wrapper{
      background: $control-nonactive-background;
      border-color: $control-nonactive-border;
      flex-shrink: 0;
      .form__checkbox__wrapper__toggler {
        border-color: $control-nonactive-border;
      }
    }
    &:hover{
      .form__checkbox__wrapper{
        //box-shadow: 1px 1px 1px 0 $btn-color-shadow-hover;

        .form__checkbox__wrapper__toggler {

        }
      }
    }

    &_border{
      padding: 0 0.875rem 0 0.875rem;
      border: 1px solid $c-grey2;
      background: #fff;
      height: 2.5rem;
    }

    input{
      display: none!important;

      &:checked, &.input_checked {
        &+.form__checkbox__wrapper{
          transition: $transition-menu;
          background: $control-active-background;
          border-color: $control-active-border;

          .form__checkbox__wrapper__toggler{
            transition: $transition-menu;
            background: #fff;
            border-color: $control-active-border;
            margin-left: 15px;

            &:before{
              //opacity: 1;
              transition: $transition-menu;
            }
          }
        }

        &+.form__checkbox__wrapper__true{
          transition: $transition-menu;
          background: $control-active-background;
          border-color: $control-active-border;

          .form__checkbox__wrapper__toggler{
            transition: $transition-menu;
            background: #fff;
            border-color: $control-active-border;
            margin-left: 15px;

            &:before{
              //opacity: 1;
              transition: $transition-menu;
            }
          }
        }

        &:hover{
          &+.form__checkbox__wrapper{
            .form__checkbox__wrapper__toggler{
              //box-shadow: 1px 1px 1px 0 $btn-color-shadow-hover;
            }
          }
        }
      }

      &[disabled],
      &[readonly]{

        & + .form__checkbox__wrapper{
          cursor: not-allowed !important;
          background: $control-disabled-background;
          border-color: $control-disabled-border;

          .form__checkbox__wrapper__toggler{
            //background: $checkbox-color-toggler-disabled;
            background: #fff;
            border-color: $control-disabled-border;
          }
        }

        & ~ .form__checkbox__content{
          cursor: not-allowed !important;
        }
      }

      &[disabled],
      &[readonly]{
        & + .form__checkbox__wrapper__true{
          cursor: not-allowed !important;
          background: #db9595;
          border-color: $control-disabled-border;

          .form__checkbox__wrapper__toggler{
            //background: $checkbox-color-toggler-disabled;
            background: #fff;
            border-color: $control-disabled-border;
          }
        }

        & ~ .form__checkbox__content{
          cursor: not-allowed !important;
        }
      }
    }

    .form__checkbox__wrapper{
      margin-right: 1rem;
      display: inline-block;
      border-radius: 60px;
      position: relative;
      width: $checkbox-width;
      height: $checkbox-height;
      background: $control-nonactive-background;
      border: solid $control-nonactive-border $checkbox-border-width;
      transition: $transition-menu;

      .form__checkbox__wrapper__toggler{
        @extend %checkbox-toggler;
        color: $c-checkbox;

        &_dark{
          @extend %checkbox-toggler;
          color: $text-gray-color;
        }

        &:before{
          opacity: 0;
          transition: $transition-menu;
        }
      }
    }
    .form__checkbox__wrapper__true{
      margin-right: 1rem;
      display: inline-block;
      border-radius: 60px;
      position: relative;
      width: $checkbox-width;
      height: $checkbox-height;
      border: solid $control-nonactive-border $checkbox-border-width;
      transition: $transition-menu;

      .form__checkbox__wrapper__toggler{
        @extend %checkbox-toggler;
        color: $c-checkbox;

        &_dark{
          @extend %checkbox-toggler;
          color: $text-gray-color;
        }

        &:before{
          opacity: 0;
          transition: $transition-menu;
        }
      }
    }
  }

  /* CRM */
  &_crm{
    .form__group{
      margin-bottom: 0.4rem;
    }
    .form__label{
      color: $btn-label;
      margin-bottom: 0.15rem;
      color: rgb(0, 0, 0);

      &_require {
        &:before {
          //top: 0.375rem;
          //left: -0.75rem;
          //height: 0.375rem;
          //width: 0.375rem;
        }
      }
    }

    button.button, .button{
      //@extend %button_sm;
    }

    .select2-selection{
      //border: 1px solid #BABABA !important;
    }
    .select2-container--default .select2-selection--single{
      height: 2rem !important;
      font-size: 0.85rem;
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow{
      top: 4px !important;
      right: 6px!important;
    }
    .select2-container--default .select2-selection--single .select2-selection__rendered{
      padding-top: 0 !important;
      height: 1.7rem;
      padding-right: 15px!important;
      line-height: 1.6rem !important;
      span {
        display: block !important;
      }
    }
    .select2-container--default .select2-selection--multiple .select2-selection__choice{
      padding: 1px 18px 1px 5px;
      font-size: 0.85rem;
    }

    .form__input{
      //border:  1px solid $c-input-border;

      &_disabled{
        //border-color: $c-input-border;
      }
      &_icon{
        top: 7.5px;
      }
      &-close{
        top: 0px;
        bottom: 0px;
        margin: auto;
      }

      .input, .input__placeholder{
        height: 1.875rem;
        padding: 0.16rem 0.875rem 0.16rem 0.875rem;
        font-size: 0.85rem;

        span{
          line-height: 1.8rem;
          height: 1.8rem;
        }
      }
      .textarea, .textarea__placeholder{
        font-size: 0.85rem;
        line-height: 1.1rem;
        padding: 0.1rem 0.475rem 0.16rem 0.475rem;
      }
    }
    .form__checkbox{
      input:checked {
        .form__checkbox__wrapper {
          .form__checkbox__wrapper__toggler {
            //margin-left: 15px !important;
          }
        }
      }
      &__wrapper{
        &__toggler{

        }
      }
    }

  }
}

.table .form_crm .form__input .input__placeholder {
  padding-top: 0;
}

.checkbox {
  height: 18px;
  width: 18px;
  border: 1px solid #979797;
  border-radius: 2px;
}

.textarea{
  @extend %form-input;
  height: auto;
  padding: 0.4rem 0.84rem 0.4rem 0.84rem;

  &:disabled{
    border: solid $c-dark-grey 0;
    background:$c-grey !important;
    cursor: not-allowed;

    &:hover{
      box-shadow: 1px 1px 1px 0 $btn-color-shadow-hover;
    }
  }

  &__placeholder{
    @extend %form-input;
    span{
      line-height: 2.325rem;
    }

    &_pointer{
      cursor: pointer;
    }
  }
}

.input{
  @extend %form-input;

  &__placeholder{
    @extend %form-input;

    span{
      line-height: 2.15rem;
      height: 2.05rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
    }

    &_pointer{
      cursor: pointer;
    }
  }

  &__group__wrapper {
    &__label{
      @extend %input-label;
    }
  }

  &__group {
    display: flex;

    > * {
      max-width: 100%;
      flex-grow: 1;
    }

    > .form__group,
    > app-material-input,
    > app-material-select,
    > app-material-input-autocomplete {
      .form__input {
        border-left: 0;
      }

      &:nth-child(1) {
        .form__input {
          border-left:  1px solid $c-grey;
        }
      }

      &+app-material-select{
        /*
         * Shift select dropdown if not first element in group
         */
        .form__group__dropdown{
          width: calc(100% + 3px);
          margin-left: -3px;
        }
      }
    }

    &_dashboard {
      > *:first-child {
        flex-basis: 40%;
      }
      > *:last-child {
        flex-basis: 60%;
        flex-shrink: 2;
      }

      @include media-md{
        & {
          flex-wrap: wrap;
        }
        & > *:first-child {
          flex-basis: 100%;
        }
        & > *:last-child {
          flex-basis: 100%;
        }
        & .form__input {
          border-left: 0;
        }
      }
    }
  }

  &_icon{
    padding-right: 3rem;
  }

  &_no-height{
    height: auto !important;
  }

  &_disabled,
  &[disabled],
  &[readonly] {
    background: $c-grey;
    cursor: not-allowed;
  }
}

.row__middle{
  align-items: center;
}

.alert{
  display: block;
  width: 100%;
  min-height: 3rem;
  padding: 1rem 1.25rem;
  border-radius: 0 !important;
  color: $text-gray-color;
  position: relative;
  margin-bottom: 0;
  margin-top: 0;

  &_danger{
    @extend .bg-danger;
  }
  &_info{
    @extend .bg-info;
  }
  &_success{
    @extend .bg-success;
  }
  &_warning{
    @extend .bg-warning;
  }
  &_default{
    @extend .bg-default;
  }
  &_primary{
    @extend .bg-primary;
  }


  &__close{
    display: block;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 10px;
    padding: 0;
    margin: auto;
    background: 0 0;
    border: 0;
    width: 16px;
    height: 16px;
    cursor: pointer;
    line-height: 16px;
    font-size: 16px;
    font-family: $font-weight-bold;
    outline: none;
  }
}

.notify {
  display: block;
  position: fixed;
  right: 0;
  top: 1rem;
  padding: 0 1rem 0 0;
  z-index: $z-modal + 10;
  @media(max-width: 768px) {
    padding: 0;
    width: 100%!important;
    bottom: 0;
    top: auto;
    &__item {
      width: 100%!important;
      margin-bottom: 0 !important;
      border-bottom-left-radius: 0!important;
      border-bottom-right-radius: 0!important;
    }
  }
  &__item {
    padding: 1rem 1rem 1rem 1rem;
    display: block;
    position: relative;
    margin-bottom: 1.1rem;
    width: 422px;
    border: 1px solid $c-grey2;
    border-radius: 1rem;
    background-color: $clr-white;
    //box-shadow: inset 32px 0 0 0 $c-dark-grey, 0 1px 4px $c-grey;
    cursor: pointer;
    transition: all 40ms linear;
    //animation: opacity .2s;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
    &-content {
      padding-left: 1.65rem;
      a {
        text-decoration: underline;
      }
    }
    &_success {
      //box-shadow: inset 32px 0 0 0 $c-green, 0 1px 4px $c-grey;
    }

    &_danger {
      //box-shadow: inset 32px 0 0 0 $c-red, 0 1px 4px $c-grey;
    }

    &-header {
      @extend %text;
      padding-bottom: 0.25rem;

      .icon{
        font-size: 1.1rem;
        float: left;
        margin-right: 0.5625rem;
      }
    }
    &-content {
      @extend %text-sm;
    }
    &-close {
      display: none;
      position: absolute;
      top: 13px;
      right: 10px;
      padding: 0;
      margin: 0;
      background: transparent;
      border: 0;
      width: 16px;
      height: 16px;
      cursor: pointer;
      line-height: 16px;
      font-size: 16px;
      font-family: $font-weight-bold;
    }
  }
}

.modal{
  &-dialog{
    @include media-breakpoint-down(sm) {
      margin: 0rem auto;
    }
  }
  &-content{
    @include media-breakpoint-down(sm) {
      margin: 0!important;
    }
  }
  &__content{
    padding: 1.75rem 2rem 1.75rem 2rem;
    border: 0;
    border-radius: 4px;
    background-color: $c-white2;
    box-shadow: 0 0 21px 0 rgba(0,0,0,0.24);
    @media (max-width: 768px) {
      overflow: auto;
      padding: 1.75rem 1rem 1.75rem 1rem;
      border-radius: 0px;
      box-shadow: none;
    }
  }
  &__header{
    margin-bottom: 1.1rem;
    @extend %h3;
    padding: 0;
    border: 0 !important;
  }
  &__body{
    margin: 0;
    padding: 0;
  }
  &__footer{
    padding: 0;
    margin-top: 1.4rem;
    border: 0 !important;
  }

  &__close {
    font-size: 0.875rem;
    font-weight: 100;
    opacity: .8;
    margin: -0.8rem -1rem -1rem auto;
    @media (max-width: 768px) {
      display: none;
    }
    &:hover{
      opacity: 1;
    }
  }
}

.catalog{
  .accordion-title{
    margin-top: 2em;
    margin-bottom: 0.9375rem;
    line-height: 1.5rem;

    >a.accordion-msg{
      font-size: 1.5rem!important;
    }
  }
  .panel-collapse{
    padding-left: 1.375rem !important;

    h1,
    h2{
      margin-top: 2em;
      margin-bottom: 0.9375rem;
      font-size: 1.875rem;
    }

    h3,
    h4{
      margin-top: 1.5em;
      margin-bottom: 0.9375rem;
      font-weight: normal;
      font-size: 1.5rem;
      line-height: 1.5rem;
    }

    p{
      margin-bottom: 0.625rem;
    }
  }
}

.accordion-content{
  img{
    max-width: 100%;
    margin-bottom: 0rem;
    margin-top: 0.5rem;
    height: auto;
  }
}

// Placeholder
input {
  &::-webkit-input-placeholder { color: $c-placeholder; font-weight: 300; }
  &::-moz-placeholder { color: $c-placeholder; font-weight: 300; }
  &:-moz-placeholder { color: $c-placeholder; font-weight: 300; }
  &:-ms-input-placeholder { color: $c-placeholder; font-weight: 300; }
  &::-ms-input-placeholder { color: $c-placeholder; font-weight: 300; }
  &::placeholder { color: $c-placeholder; font-weight: 300; }
}
textarea {
  &::-webkit-input-placeholder { color: $c-placeholder; font-weight: 300; }
  &::-moz-placeholder { color: $c-placeholder; font-weight: 300; }
  &:-moz-placeholder { color: $c-placeholder; font-weight: 300; }
  &:-ms-input-placeholder { color: $c-placeholder; font-weight: 300; }
  &::-ms-input-placeholder { color: $c-placeholder; font-weight: 300; }
  &::placeholder { color: $c-placeholder; font-weight: 300; }
}


/* Specify Blocks */

// Minimap
.minimap{
  display: inline-block;
  //text-transform: uppercase;
  @extend %text;
  vertical-align: middle;
  height: 2.375rem;
  line-height: 2.375rem;
  position: relative;
  top: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0.5px;
  font-size: $font-size-button;

  color: $text-gray-color;
  background-color: $clr-white;
  border: solid $c-grey2 1px;
  border-radius: $btn-border-radius;
  padding: 0;
  transition: all 40ms linear;
  box-shadow: 0 0 7px 0px rgba(0, 0, 0, 0.1);

  &__item {
    padding: 0 0.5rem;
    position: relative;
    z-index: 1;
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
  }
  &__item:first-child {
    padding: 0 0.5rem 0 1rem;
  }
  &__item:last-child {
    padding: 0 1rem 0 0.5rem;
  }

  &__slide-bg {
    position: absolute;
    z-index: 0;
    height: 3px;
    bottom: 0;
    width: 0;
    outline: none;
    padding: 0;
    margin: 0;
    border: 0;
    background: $tab-active-background;
  }

  &__slide{
    position: absolute;
    z-index: 2;
    height: inherit;
    width: 0;
    outline: none;
    padding: 0;
    margin: 0;
    border: 0;
    cursor:ew-resize;
    background: transparent;

    //&:hover {
    //  box-shadow: 0 0 3px 1px rgba(13, 51, 73, 0.12);
    //}

    &:active{
      outline: none;
      margin: 0;
      padding: 0;
    }
    &:focus{
      outline: none;
    }
  }
}

// Legenda
%legend-before{
  text-align: center;
  @extend %text-sm;
  position: absolute;
  left: 67%;
  top: -28%;
  z-index: 7;
  color: $clr-white;
  font-size:0.6rem;
  padding: 0.1rem 0.2rem 0.1rem 0.1rem;
  display: block;
}
.legend{
  &__circle{
    display: block;
    float: left;
    width: 0.6rem;
    height: 0.6rem;
    background: black;
    border-radius: 50%;

    &_kvartira{
      background: $c-kvartira;
    }
    &_no-kvartira{
      background: $c-no-kvartira;
    }

    &_sale{
      background: $c-sale;
    }
    &_reserv{
      background: $c-reserv;
    }
    &_bron{
      background: $c-bron;
    }
    &_sales{
      background: $c-sales;
    }
  }

  &__text{
    display: block;
    margin-top: 0.80rem;
    @extend %text-sm;
    margin-left: 0.2rem;

    &_left{
      float: left;
      margin-top: 0.40rem;
      margin-left: 0.4rem;
    }
  }

  &__kv-wrap{
    width: 9rem;
    padding: 0;
    margin: 0;
  }

  &__kv-hidden{
    overflow: hidden;
    height: 54px;
    padding-top: 1rem;
  }

  &__kv{
    width: 7rem;
    margin: auto;
    cursor: default !important;
    position: relative;
    padding-top: 0.25rem;

    &_a{
      &:before{
        background: $clr-red; content: "А";
        @extend %legend-before;
      }
    }
    &_per{
      &:before{
        background: $c-green; content: "ПЕР";
        @extend %legend-before;
      }
    }
    &_p{
      &:before{
        background: $clr-red; content: "П";
        @extend %legend-before;
      }
    }
    &_ddu{
      &:before{
        background: $c-green; content: "ДДУ";
        @extend %legend-before;
      }
    }
    &_snp{
      &:before{
        background: $clr-red; content: "СПН";
        @extend %legend-before;
      }
    }
    &_otdp{
      &:before{
        background: $clr-red; content: "ПЧ";
        @extend %legend-before;
      }
    }
    &_otdch{
      &:before{
        background: $clr-red; content: "Ч";
        @extend %legend-before;
      }
    }
  }
}

.dashboard-search{
  .select2-selection__arrow{
    display: none;
  }
}
.dashboard-search__select-dropdown {
  position: relative;
  top: -40px;
  .select2-results{
    background: #fff;
    overflow: hidden;
    &__option{
      border: solid 1px $panel-color-border;
      border-top: 0;
    }
  }
  .select2-search--dropdown{
    border-bottom: transparent;
    padding: 6px 4px 7px 4px;
  }

  .select2-search__field{
    border: 0 !important;
    outline: none !important;
    margin: 2px 0.4rem 2px 0;
    width: calc(100% - 4px);
    display: block;
    padding: 4px 12px;
  }
  &.select2-dropdown--below{
    border: 0px!important;
    background: transparent;
  }
}
.long-select2{
  .select2-results>.select2-results__options {
    max-height: 336px;
  }
}
.select2-results__option.select2-results__message:empty {
  display: none;
}
.instruction{
  h1{
    margin-bottom: 1rem;
  }
  h2{
    margin-bottom: 0.8rem;
  }
  h3{
    margin-bottom: 0.6rem;
  }
  p{
    margin-bottom: 0.6rem;
  }
  ul{
    margin-bottom: 0.6rem;
  }
  li{
    margin-bottom: 0.2rem;
  }
}
.instruction-image{
  width: 160px;
  height: 160px;
  background-size: cover;
  border-radius: 3px;
  border: 1px solid $c-grey;
  cursor: pointer;
}

.oferta{
  text-align: right;
  font-size: 11px;
}


.ball-grid-pulse > div {
  background-color: $clr-red;
}


.overflow-visible{
  overflow: visible!important;
}

.position-sticky-fix{
  position: sticky;
  top: -1px;
  background: #6d6d6d!important;
  color: #fff;
  z-index: 9;
}

.min-height-auto{
  min-height: auto!important;
}

.max-height-none{
  max-height: none!important;
}

.line-through{text-decoration: line-through;}

.row-scroll{
  overflow-x: auto;
  white-space: nowrap;
  display: block;
  padding-bottom: 2rem;
  >div{
    vertical-align: top;
    float: none;
    display: inline-block;
  }
}


.text-preview{
  // margin: 1rem 0;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 0.3rem;
  position: relative;
  width: 100%;
  &.active{
    padding-bottom: 3.5rem;
    max-height: 100%!important;
    .text-preview__drop{
      height: 2.5rem;
      .icon{
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
      }
    }
  }
  &__drop{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(to bottom, rgba(250, 250, 250, 0) 0%, rgba(250, 250, 250, 1) 85%);
    height: 2.5rem;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    box-shadow: inset 0 -3px 3px -2px rgb(255, 255, 255);
    &:hover{
      background: linear-gradient(to bottom, rgba(240, 240, 240, 0) 0%, rgba(240, 240, 240, 1) 85%);
    }
    .icon{
      margin-top: 0.5rem;
      display: inline-block;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      align-self: flex-end;
      height: 1rem;
      margin-bottom: 1rem;
    }
  }
}


.fixed_buttons_block{
  position: fixed;
  bottom: 2rem;
  z-index: 10;
  margin-left: 1rem;
}


.fixed_buttons_block_main{
  position: fixed;
  bottom: 5rem;
  z-index: 10;
  top: auto;
  &.right{
    right: 1rem;
  }
}

.button_right_position{
  position: absolute;
  margin-left: auto;
  display: block;
  right: 1rem;
  bottom: 1rem;

}

.end_block_padding {
  padding-bottom: 6rem!important;
}

.select2-icon{
  &:before {
    font-size: 1rem;
  }
  &.line-icon {
    display: block;
    width: 3px;
    height: auto;
    margin-right: 0.3rem;
    flex-shrink: 0;
  }
}

.select2-selection__choice{
  .select2-icon{
    &:before {
      color: #fff;
    }
  }
}

.history{
  background: transparent!important;
  &__gray{
    color: $clr-black-soft;
  }
  &__colorblock{
    width: 0.8rem;
    height: 0.8rem;
    margin: 0 0.2rem -0.1rem 0;
    display: inline-block;
    &.success{
      background: #28a745;
    }
    &.prolongation{
      background: #6c757d;
    }
    &.fail{
      background: #de1d25;
    }
    &.roll_back{
      background: #FFB217;
    }
    &.new{
      background: #FFFFFF;
    }
    &.finish{
      background: #28a745;
    }
    /* действие с клиентом (эст-а-тет) */
    &.action{
      background: #9baed0;
    }
  }
}


.red-mobile-header{
  display: none;
  @media(max-width: 768px){
    display: flex;
    padding: 0.8rem 1.5rem 0.7rem;
    margin-bottom: 1rem;
    background: $c-red;
    color: #fff;
    align-items: center;
  }
  h2{
    margin-bottom: -2px;
    font-size: 1.2rem;
    line-height: 1;
  }
}

iframe{
  @media(max-width: 768px){
    max-width: 100%;
    min-width: 0!important;
  }
}

@media(min-width: 769px){

  *{
    &::-webkit-scrollbar {
      width: 15px;
      height: 15px;
    }
    &::-webkit-scrollbar-thumb {
      background-clip: content-box;
      border: 4px solid transparent;
      border-radius: 7px;
      box-shadow: inset 0 0 0 10px;
      color: #9a9a9a;
    }
    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }
    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }
}






.dashboard-search {
  .search-round-block {
    background: #fff;
    border-radius: 3rem;
    border: 1px solid #aaa;
    overflow: hidden;
    min-height: 2.63rem;
    select{
      border: 0;
      outline: none;
      background: transparent;
    }
    .select2-selection--single{
      outline: none;
      border: 0px!important;
    }
  }
}




@include device-sm{
  .overflow-a{
    overflow: auto;
    max-width: 100%;
    &>*{
      width: max-content!important;
      min-width: 100%;
    }
    .navigation__tabs{
      a{
        padding: 0.5rem 0;
        margin: 0 1rem 0 0;
        text-align: center;
      }
    }
    &.search{
      margin: 0 -1rem;
      max-width: 100vw;
      .btn-group{
        padding: 0 1rem;
      }
      .search__grouper{
        padding: 0 1rem;
        .btn-group{
          padding: 0;
        }
      }
    }
  }
}

// map
#map-auto-height{
  min-height: 500px;
}

@media(min-width: 769px){
  .show-map{
    .show-map__hide-toggle {
      display: none;
    }
    .footer {
      display: none;
    }
    .info-logo{
      display: none;
    }
    .main-wrapper{
      padding: 0 0 0 $sidebar-width-min;
    }
    .content {
      margin-bottom: 0;
    }
    .sidebar{
      width: $sidebar-width-min;
      overflow: hidden;
      &_active{
        width: $sidebar-width;
      }
      &:hover{
        width: $sidebar-width;
      }
    }
    .red_card_links {
      left: $sidebar-width-min !important;
    }
    .sidebar-wrapper {
      width: $sidebar-width-min;
    }
  }
  .map-block {
    display: flex;
    flex-direction: row-reverse;
    margin: -1rem;
    #map-auto-height{
      height: 100vh;
      max-height: 70rem;
      min-height: 10rem;
    }
    .dashboard {
      width: 475px;
      display: flex;
      flex-direction: column;
      height: 100vh;
      max-height: 100rem;
    }
    &__vitrina{
      max-height: 100%;
      overflow: auto;
      height: auto!important;
    }
    .dashboard__panel {
      flex: 1;
    }
    .card {
      margin-bottom: 0px!important;
    }

    .spoiler__wrapper{
      &__mobile-header {
        display: flex!important;
        background: $clr-red;
        margin: -0.7rem -1rem 1rem;
        padding: 0.7rem 1rem;
      }
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      max-height: 70rem;
      z-index: 999;
      margin: 0!important;
      overflow: auto;
      opacity: 0;
      transition: all 400ms ease 0s;
      margin-left: 100%;
      &.active{
        opacity: 1;
        margin-left: 0;
      }
    }
    .clear-d-none{
      display: none!important;
    }
    .d-map-block{
      .button-group-main {
        display: block!important;
        .button {
          margin-bottom: 0.2rem;
        }
      }
    }
    .panel_noborder{
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    .wrapper-block{
      padding: 1rem 1rem 0.5rem!important;
      margin-bottom: 0;
    }

    .floor-min-width{
      width: 17rem;
    }

    .white-block{
      background: none;
      padding: 0;
      border: 0;
      .d-table-cell + .d-table-cell{
        border-left: 1px solid #000;
      }
    }


    .row-search-footer{
      background: $clr-yellow;
      margin: 0;
      .search-button{
        button {
          border-radius: 0!important;
          padding: 0 0.65rem;
        }
      }
    }
    .col-12{
      flex: 0 0 100%;
      max-width: 100%;
    }
    .col-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .card-block{
      margin: 0rem 0 0!important;
      padding-top: 1rem!important;
    }
    .mobile-flex{
      display: flex;
      padding-bottom: 0!important;
      padding-top: 0!important;
      width: 100%;
      >*:nth-child(1){
        flex-grow: 1;
        width: calc(100% - 2.4rem);
      }
      &__text{
        display: none;
      }
      &__icon{
        flex-basis: 2.8rem;
        justify-content: flex-end;
        .icon{
          font-size: 1.2rem;
          margin-top: 0.68rem;
          transform: rotate(-90deg);
        }
      }
    }
    .mobile-tabs{
      display: block;
      .line-holder{
        margin: -1rem -1rem 1rem!important;
      }
      ul{
        white-space: normal;
        width: 100%!important;
        align-items: stretch!important;
        li.navigation__tab{
          white-space: normal!important;
          flex: 1!important;
          margin: 0!important;
          a{
            padding: 1rem 1rem!important;
            color: #fff!important;
            background: $clr-red!important;
            text-align: left!important;
            margin: 0!important;
            height: 100%!important;
            display: flex!important;
            align-items: center!important;
            justify-content: center!important;
            text-align: center!important;
            span{
              display: none!important;
            }
            &.navigation__tab__link_active{
              background: $clr-yellow!important;
            }
            &:hover{
              background: $clr-yellow!important;
            }
          }
        }
      }
    }
    .item__wrapper {
      border-radius: 0px!important;
      border: 0!important;
      &.bordered{
        background-color: $clr-yellow-opacity-2x !important;
      }
    }
  }
}
@media(max-width: 768px) {
  .show-map{
    overscroll-behavior: contain;
    .main-wrapper {
      padding: 0;
    }
    .mobile_show__trigger{
      position: absolute;
      z-index: 9;
      top: 4.2rem;
      right: calc(50% - 5rem);
      width: 10rem;
    }
    #map-auto-height{
      margin-top: -3.64rem;
      max-height: calc(100vh - 80px) !important;
    }
    .red_card_links{
      display: none!important;
    }
    .footer{
      display: none;
    }
    #map-item-holder{
      background: $clr-gray-darklight;
      width: 100%;
      overflow: auto;
      bottom: 0;
      position: fixed;
      left: 0;
      z-index: 9;
      height: 267px;
      min-height: 2rem!important;
      max-height: calc(100vh - 55px) !important;
      top: auto!important;
      display: flex;
      flex-direction: column;
      .resize-handle-top{
        padding: 0.4rem 0;
        background: #f2f2f2;
        width: 100%;
        flex-shrink: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2rem;
        span{
          width: 1rem;
          height: 4px;
          border-radius: 4px;
          margin: 0.2rem 0.2rem 0.3rem;
          background: $c-red;
          display: block;
        }
      }
      .map-block__vitrina__block{
        overflow: auto;
      }
    }
    .item__wrapper {
      &.bordered{
        background-color: $clr-yellow-opacity-2x !important;
      }
    }
    .osm-map{
      height: calc(100vh - 80px) !important;
      max-height: 1000px;
    }
    .new_panel{
      padding: 0;
    }
  }
}




.clr-yellow-inside{
  color: $clr-yellow-inside !important;
}

.clr-red-inside{
  color: $clr-red-inside !important;
}

.ps__rail-y{
  z-index: 2;
}

.table{
  .td-graph{
    padding-left: 1.6rem!important;
    position: relative;
    vertical-align: middle;
    &__icon{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0.3rem;
      margin: auto;
      height: 1rem;
      &:before {
        font-size: 0.6rem;
      }
    }
  }
}

$icon: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDExIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgNC40MTRMMy43MDcgOC4xMjFMMTAuNDE0IDEuNDE0TDkgMEwzLjcwNyA1LjI5M0wxLjQxNCAzTDAgNC40MTRaIiBmaWxsPSJibGFjayIvPjwvc3ZnPg==);

.checkbox{
  display: inline-flex;
  width: 1.2rem;
  height: 1.2rem;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  input{
    display: none!important;
    visibility: hidden!important;
  }

  input:checked+span{
    content: $icon;
  }
}

$entities-icon: (
  "housing": url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTggMEMzLjU4MTcyIDAgMCAzLjU4MTcyIDAgOEMwIDEyLjQxODMgMy41ODE3MiAxNiA4IDE2QzEyLjQxODMgMTYgMTYgMTIuNDE4MyAxNiA4QzE2IDMuNTgxNzIgMTIuNDE4MyAwIDggMFpNMSA4QzEgMTEuMTcxIDMuMTA4NTEgMTMuODQ5NiA2IDE0LjcxMDFWNkgxLjI4OTg4QzEuMTAxMjggNi42MzM3MSAxIDcuMzA1MDMgMSA4Wk01IDVIN1YxNC45MjkxQzcuMzI2NiAxNC45NzU4IDcuNjYwNDggMTUgOCAxNUM5LjA3MzYgMTUgMTAuMDkwNyAxNC43NTgzIDExIDE0LjMyNjRWNEMxMSAzLjQ0NzcyIDEwLjU1MjMgMyAxMCAzSDZDNS40NDc3MiAzIDUgMy40NDc3MiA1IDRWNVpNMTIgOVYxMy43NDUzQzEzLjU1NDYgMTIuNjYwOSAxNC42NDg1IDEwLjk2MTYgMTQuOTI5MSA5SDEyWiIgZmlsbD0iYmxhY2siIGZpbGwtb3BhY2l0eT0iMC45Ii8+PC9zdmc+),
  "house": url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTAgOEMwIDMuNTgxNzIgMy41ODE3MiAwIDggMEMxMi40MTgzIDAgMTYgMy41ODE3MiAxNiA4QzE2IDEyLjQxODMgMTIuNDE4MyAxNiA4IDE2QzMuNTgxNzIgMTYgMCAxMi40MTgzIDAgOFpNMTEgMTQuMzI2NFY1QzExIDQuNDQ3NzIgMTAuNTUyMyA0IDEwIDRINkM1LjQ0NzcyIDQgNSA0LjQ0NzcyIDUgNVYxNC4zMjY0QzUuOTA5MjYgMTQuNzU4MyA2LjkyNjQgMTUgOCAxNUM5LjA3MzYgMTUgMTAuMDkwNyAxNC43NTgzIDExIDE0LjMyNjRaIiBmaWxsPSJibGFjayIgZmlsbC1vcGFjaXR5PSIwLjkiLz48L3N2Zz4=),
);
h1{

}
span{
  &.caption{
    &.complex{
      &:before{
        content: map_get($entities-icon, "housing");
        display: inline-block;
        padding-right: .3rem;
        vertical-align: middle;
      }
    }
    &.house{
      &:before{
        content: map_get($entities-icon, "house");
        display: inline-block;
        padding-right: .3rem;
        vertical-align: middle;
      }
    }
  }
}

.perfect-scrollbar {
  overflow: auto;
}

.ymaps-2-1-79-controls__control_toolbar:has(.ymaps-2-1-79-searchbox),
.ymaps-2-1-79-controls__control_toolbar:has(.ymaps-2-1-79-float-button-icon_icon_expand) {
  display: none!important;
}

ya-map {
  display: block;
  height: 100%;
  > div > ymaps {
    width: 100%!important;
  }
}

@media (max-width: 768px) {
  #map-auto-height > ya-map {
    height: calc(100vh - 80px);
  }
}
