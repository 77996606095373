@import "common_styles/scss/variables";
@import "common_styles/scss/fonts";
@import "common_styles/scss/mixins";
@import "~@fancyapps/fancybox/dist/jquery.fancybox.css";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/leaflet-draw/dist/leaflet.draw.css";

@import "common_styles/scss/table";
@import "common_styles/scss/icons";
@import "common_styles/scss/icons2";
@import "common_styles/scss/template";
@import "common_styles/scss/template-crm";
@import "common_styles/scss/loading";
@import "common_styles/scss/frame-crm";
@import "common_styles/scss/panel";
@import "common_styles/scss/select2_overrides";
@import "common_styles/scss/indents";
@import "common_styles/scss/tabs";
@import "common_styles/scss/yandex_map";
@import "common_styles/scss/bootstrap_override";
@import "common_styles/scss/lightbox";
@import "common_styles/scss/svg-container";
@import "common_styles/scss/tooltip";
@import "common_styles/scss/osm/osm";

#dashboard-yandex-map{
  margin: 0!important;
}

html{
  font-size: .9375rem;
}

body{
  min-height: 100%;
  height: auto;
  position: relative;
  background: #F2F2F2;
}

.leaflet-control-attribution svg {
  display: none;
}
