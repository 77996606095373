@import "./variables";
@import "./modifiers";

/* Global */
.table-responsive {
  display: block;
  width: 100%;
  min-height: 20rem;
  overflow-x: auto;
}

.table-bordered {
  tr:first-child {
    border-top: 2px solid #E4E4E4;
  }
  tr:last-child {
    border-bottom: 2px solid #E4E4E4;
  }
}

/* BEM Table standart */
.table{
  margin-top: 0.5rem;
  margin-bottom: 0;

  tr{
    &:focus{
      outline: 0;
    }
  }
  tbody tr:hover{
    background-color: $tr-yellow-background !important;
    @media (max-width: 768px) {
      background: $c-grey-a5 !important;
    }
  }
  th{
    @extend %text-sm;
    border: 0;
    border-bottom: 1px solid $c-table-border !important;
    border-right: 0;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;
    font-weight: 300 !important;
    color: rgba(0, 0, 0, 0.36);
    vertical-align: middle!important;
    &:last-child{
      border-right: 0;
    }

    a{
      color: rgba(0, 0, 0, 0.36);
      text-decoration: none;
      &:hover{
        text-decoration: none;
      }
    }
  }
  thead{
    th{
      @extend %text-sm;
      vertical-align: middle!important;
    }
  }
  td{
    border: 0;
    padding: 0.4625rem 0.4rem 0.4625rem 0.4rem;
    border-bottom: 1px solid $c-table-border;
  }

  &_middle{
    th,
    td{
      vertical-align: middle!important;
    }
  }

  &_vertical{
    th{
      border-top: 1px solid $c-table-border;
      vertical-align: middle;
      font-weight: bold !important;
      border-right: 0;
      padding: 0.3rem !important;
    }
    td{
      border-top: 1px solid $c-table-border;
      border-bottom: 1px solid $c-table-border;
    }
  }

  &__input{

    .form__input{
      border: 0;
      border-bottom: 1px solid $c-grey;
    }
    .input{
      height: 1.6rem;
      padding: 0.2rem 0.4rem;
      background: $c-white2;
    }
    .form__input-close{
      top: 5px;
    }
    .input__placeholder{
      height: 1.6rem;
      padding: 0 0.4rem 0.2rem;

      span{
        line-height: 1.6rem;
      }
    }
    .form__input_icon{
      top: 5px !important;
    }
  }
  &.datatable{
    margin-top: 0;
  }

  &__tr{
    &_selected{
      color: rgba(0, 0, 0, 0.45);
    }
  }
}


/* BEM Table swap in card at min width */
$breakpoint-alpha: 767px; // adjust to your needs

@media (max-width: $breakpoint-alpha) {
  .table-rwd {
    margin: -1rem 0 1rem 0;;
    thead{
      display: none;
    }
    tr {
      background: $c-grey-a5;
      border-radius: 4px;
      margin-bottom: 1rem;
      display: block;
      &.no-bord {
        margin-bottom: 0px;
        margin-bottom: -2px;
      }
    }
    &.no-bord{
      tr {
        background: transparent;
        border-radius: 0;
        margin: 0;
        border-bottom: 1px solid #eee;
        padding: 1rem 0;
        td {
          padding: 0!important;
        }
      }
    }
    th {
      border:0 !important;
      display: none; // for accessibility, use a visually hidden method here instead! Thanks, reddit!
    }

    &.history{
      td {
        display: block;
      }
    }

    td {
      padding: 0.25rem 1rem 0.25rem 1rem !important;
      border:0 !important;
      display: flex;
      align-items: center;

      &:first-child {
        padding-top: 1rem !important;
      }
      &:last-child {
        padding-bottom: 1rem !important;
      }

      &.data-th {
        text-align: left!important;
        &.th-inline {
          display: inline-block;
        }
        &:before {
          content: attr(data-th) ": ";
          color: $clr-black-total;
          font-family: $font-family-base;
          font-size: $font-size-sm;
          line-height: $linge-height-base;
          opacity: 0.7;
          min-width: 8rem;
          max-width: 8rem;
          display: inline-block;
          margin-right: 0.5rem;
          text-align: left;

          @at-root (without: media) {
            @media (min-width: $breakpoint-alpha) {
              display: none;
            }
          }
        }
        >*{
          text-align: left!important;
          &.text-right{
            text-align: left!important;
          }
        }
      }
      &.data-th-min {
        text-align: left!important;
        &.th-inline {
          display: inline-block;
          margin-right: 0.6rem;
          margin-top: 0.6rem;
          &:before {
            margin-right: 0.3rem;
            font-weight: normal;
          }
        }
        &:before {
          content: attr(data-th) ": ";
          color: $clr-black-total;
          font-family: $font-family-base;
          font-size: $font-size-base;
          line-height: $linge-height-base;
          opacity: 0.7;
          display: inline-block;
          margin-right: 0.8rem;
          text-align: left;

          @at-root (without: media) {
            @media (min-width: $breakpoint-alpha) {
              display: none;
            }
          }
        }
      }

    }
    th, td {
      text-align: left;
    }

  }
}

.table{
  &_favorites-list{
    th{
      font-size: .8rem!important;
      white-space: nowrap;
    }

    tr{
      cursor: pointer;
    }
  }
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: $tr-gray-background!important;
}

